; (function (lib, img, cjs) {
  var p // shortcut to reference prototypes

  // library properties:
  lib.properties = {
    width: 321,
    height: 311,
    fps: 20,
    color: '#455D5C',
    manifest: [{ src: 'img/WOM0130_TABVBG.png', id: 'WOM0130_TABVBG' }],
  }

    // symbols:

    ; (lib.WOM0130_TABVBG = function () {
      this.initialize(img.WOM0130_TABVBG)
    }).prototype = p = new cjs.Bitmap()
  p.nominalBounds = new cjs.Rectangle(0, 0, 321, 311)

    ; (lib.Whiteoutline = function () {
      this.initialize()

      // Layer 1
      this.shape = new cjs.Shape()
      this.shape.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-47.1, 111.4)
        .curveTo(-68.8, 102.2, -85.5, 85.5)
        .curveTo(-102.2, 68.8, -111.4, 47.1)
        .curveTo(-120.9, 24.6, -120.9, -0)
        .curveTo(-120.9, -24.6, -111.4, -47.1)
        .curveTo(-102.2, -68.8, -85.5, -85.5)
        .curveTo(-68.8, -102.2, -47.1, -111.4)
        .curveTo(-24.6, -120.9, -0, -120.9)
        .curveTo(24.6, -120.9, 47.1, -111.4)
        .curveTo(68.8, -102.2, 85.5, -85.5)
        .curveTo(102.2, -68.8, 111.4, -47.1)
        .curveTo(120.9, -24.6, 120.9, -0)
        .curveTo(120.9, 24.6, 111.4, 47.1)
        .curveTo(102.2, 68.8, 85.5, 85.5)
        .curveTo(68.8, 102.2, 47.1, 111.4)
        .curveTo(24.6, 120.9, -0, 120.9)
        .curveTo(-24.6, 120.9, -47.1, 111.4)
        .closePath()
        .moveTo(-45.6, -107.9)
        .curveTo(-66.6, -99, -82.8, -82.8)
        .curveTo(-99, -66.6, -107.9, -45.6)
        .curveTo(-117.1, -23.8, -117.1, -0)
        .curveTo(-117.1, 23.8, -107.9, 45.6)
        .curveTo(-99, 66.6, -82.8, 82.8)
        .curveTo(-66.6, 99, -45.6, 107.9)
        .curveTo(-23.9, 117.1, -0, 117.1)
        .curveTo(23.8, 117.1, 45.6, 107.9)
        .curveTo(66.6, 99, 82.8, 82.8)
        .curveTo(99, 66.6, 107.9, 45.6)
        .curveTo(117.1, 23.8, 117.1, -0)
        .curveTo(117.1, -23.8, 107.9, -45.6)
        .curveTo(99, -66.6, 82.8, -82.8)
        .curveTo(66.6, -99, 45.6, -107.9)
        .curveTo(23.8, -117.1, -0, -117.1)
        .curveTo(-23.9, -117.1, -45.6, -107.9)
        .closePath()
      this.shape.setTransform(120.9, 120.9)

      this.addChild(this.shape)
    }).prototype = p = new cjs.Container()
  p.nominalBounds = new cjs.Rectangle(0, 0, 241.9, 241.9)

    ; (lib.computerwhite = function () {
      this.initialize()

      // Layer 1
      this.shape = new cjs.Shape()
      this.shape.graphics
        .beginFill('#455D5C')
        .beginStroke()
        .moveTo(-67.3, 55)
        .curveTo(-69.4, 55, -70.9, 53.5)
        .curveTo(-72.3, 52, -72.3, 50)
        .lineTo(-72.3, -50)
        .curveTo(-72.3, -52, -70.9, -53.5)
        .curveTo(-69.4, -55, -67.3, -55)
        .lineTo(67.3, -55)
        .curveTo(69.4, -55, 70.9, -53.5)
        .curveTo(72.3, -52, 72.3, -50)
        .lineTo(72.3, 50)
        .curveTo(72.3, 52, 70.9, 53.5)
        .curveTo(69.4, 55, 67.3, 55)
        .closePath()
        .moveTo(-61.9, -45.4)
        .curveTo(-62.8, -44.5, -62.8, -43.3)
        .lineTo(-62.8, 42.7)
        .curveTo(-62.8, 43.9, -61.9, 44.7)
        .curveTo(-61, 45.6, -59.8, 45.6)
        .lineTo(59.8, 45.6)
        .curveTo(61.1, 45.6, 61.9, 44.7)
        .curveTo(62.8, 43.9, 62.8, 42.7)
        .lineTo(62.8, -43.3)
        .curveTo(62.8, -44.5, 61.9, -45.4)
        .curveTo(61.1, -46.2, 59.8, -46.2)
        .lineTo(-59.8, -46.2)
        .curveTo(-61, -46.2, -61.9, -45.4)
        .closePath()
      this.shape.setTransform(119, 108.2)

      this.shape_1 = new cjs.Shape()
      this.shape_1.graphics
        .beginFill('#455D5C')
        .beginStroke()
        .moveTo(-63.7, 11.7)
        .curveTo(-65.8, 11.7, -67.2, 10.1)
        .curveTo(-68.5, 8.6, -68.5, 6.6)
        .curveTo(-68.5, 5.5, -68.1, 4.5)
        .lineTo(-68.1, 4.4)
        .lineTo(-62.4, -8.7)
        .curveTo(-61.8, -10.1, -60.6, -10.9)
        .curveTo(-59.4, -11.7, -58, -11.7)
        .lineTo(58.3, -11.7)
        .curveTo(59.7, -11.7, 60.9, -10.9)
        .curveTo(62.1, -10, 62.7, -8.7)
        .lineTo(68.1, 4.5)
        .curveTo(68.5, 5.6, 68.5, 6.6)
        .curveTo(68.5, 8.6, 67.2, 10.1)
        .curveTo(65.8, 11.7, 63.7, 11.7)
        .closePath()
        .moveTo(46, 0.4)
        .curveTo(45.7, 0.7, 45.9, 1.1)
        .lineTo(47.5, 5.4)
        .curveTo(47.8, 6, 48.3, 6)
        .lineTo(56.6, 6)
        .curveTo(57.1, 6, 57.3, 5.6)
        .curveTo(57.6, 5.2, 57.4, 4.8)
        .lineTo(55.4, 0.5)
        .curveTo(55.2, 0, 54.6, 0)
        .lineTo(46.7, 0)
        .curveTo(46.2, 0, 46, 0.4)
        .closePath()
        .moveTo(-43, 0.9)
        .lineTo(-44.6, 5.2)
        .curveTo(-44.7, 5.5, -44.4, 5.7)
        .curveTo(-44.1, 6, -43.8, 6)
        .lineTo(43.6, 6)
        .curveTo(44, 6, 44.2, 5.6)
        .curveTo(44.5, 5.3, 44.4, 4.9)
        .lineTo(42.8, 0.6)
        .curveTo(42.6, 0, 42, 0)
        .lineTo(-42.1, 0)
        .curveTo(-42.6, 0, -43, 0.9)
        .closePath()
        .moveTo(-55.4, 0.5)
        .lineTo(-57.4, 4.8)
        .curveTo(-57.6, 5.2, -57.3, 5.6)
        .curveTo(-57.1, 6, -56.6, 6)
        .lineTo(-48.3, 6)
        .curveTo(-47.7, 6, -47.5, 5.4)
        .lineTo(-45.9, 1.1)
        .curveTo(-45.7, 0.8, -46, 0.4)
        .curveTo(-46.2, 0, -46.6, 0)
        .lineTo(-54.6, 0)
        .curveTo(-55.2, 0, -55.4, 0.5)
        .closePath()
        .moveTo(43.1, -7)
        .curveTo(42.9, -6.6, 43, -6.2)
        .lineTo(44.4, -2.6)
        .curveTo(44.7, -2.1, 45.2, -2.1)
        .lineTo(52.9, -2.1)
        .curveTo(53.4, -2.1, 53.6, -2.4)
        .curveTo(53.9, -2.8, 53.7, -3.2)
        .lineTo(52, -6.9)
        .curveTo(51.8, -7.3, 51.3, -7.3)
        .lineTo(43.8, -7.3)
        .curveTo(43.4, -7.3, 43.1, -7)
        .closePath()
        .moveTo(31.2, -7)
        .curveTo(31, -6.7, 31.1, -6.3)
        .lineTo(32.1, -2.7)
        .curveTo(32.3, -2.1, 32.9, -2.1)
        .lineTo(40.7, -2.1)
        .curveTo(41.1, -2.1, 41.3, -2.4)
        .curveTo(41.6, -2.8, 41.4, -3.2)
        .lineTo(40.1, -6.8)
        .curveTo(40, -7.3, 39.3, -7.3)
        .lineTo(31.9, -7.3)
        .curveTo(31.5, -7.3, 31.2, -7)
        .closePath()
        .moveTo(19.3, -7)
        .curveTo(19, -6.7, 19.1, -6.4)
        .lineTo(19.8, -2.7)
        .curveTo(19.9, -2.1, 20.6, -2.1)
        .lineTo(28.4, -2.1)
        .curveTo(28.8, -2.1, 29, -2.4)
        .curveTo(29.3, -2.7, 29.2, -3.1)
        .lineTo(28.2, -6.7)
        .curveTo(28.1, -7.3, 27.4, -7.3)
        .lineTo(19.9, -7.3)
        .curveTo(19.5, -7.3, 19.3, -7)
        .closePath()
        .moveTo(7.3, -7.1)
        .curveTo(7.1, -6.8, 7.1, -6.4)
        .lineTo(7.5, -2.8)
        .curveTo(7.5, -2.1, 8.3, -2.1)
        .lineTo(16.1, -2.1)
        .curveTo(16.5, -2.1, 16.8, -2.4)
        .curveTo(17, -2.7, 16.9, -3)
        .lineTo(16.3, -6.7)
        .curveTo(16.1, -7.3, 15.5, -7.3)
        .lineTo(7.9, -7.3)
        .curveTo(7.6, -7.3, 7.3, -7.1)
        .closePath()
        .moveTo(-4.8, -6.5)
        .lineTo(-4.8, -2.9)
        .curveTo(-4.8, -2.1, -4, -2.1)
        .lineTo(3.8, -2.1)
        .curveTo(4.2, -2.1, 4.4, -2.3)
        .curveTo(4.7, -2.6, 4.6, -2.9)
        .lineTo(4.4, -6.6)
        .curveTo(4.3, -7.3, 3.6, -7.3)
        .lineTo(-4, -7.3)
        .curveTo(-4.8, -7.3, -4.8, -6.5)
        .closePath()
        .moveTo(-16.6, -6.7)
        .lineTo(-17.3, -3)
        .curveTo(-17.3, -2.6, -17.1, -2.4)
        .curveTo(-16.8, -2.1, -16.4, -2.1)
        .lineTo(-8.6, -2.1)
        .curveTo(-7.9, -2.1, -7.8, -2.8)
        .lineTo(-7.4, -6.4)
        .curveTo(-7.4, -6.8, -7.6, -7.1)
        .curveTo(-7.9, -7.3, -8.3, -7.3)
        .lineTo(-15.8, -7.3)
        .curveTo(-16.5, -7.3, -16.6, -6.7)
        .closePath()
        .moveTo(-28.5, -6.7)
        .lineTo(-29.5, -3.1)
        .curveTo(-29.6, -2.7, -29.4, -2.4)
        .curveTo(-29.1, -2.1, -28.7, -2.1)
        .lineTo(-20.9, -2.1)
        .curveTo(-20.2, -2.1, -20.1, -2.7)
        .lineTo(-19.4, -6.4)
        .curveTo(-19.3, -6.8, -19.6, -7)
        .curveTo(-19.8, -7.3, -20.2, -7.3)
        .lineTo(-27.7, -7.3)
        .curveTo(-28.4, -7.3, -28.5, -6.7)
        .closePath()
        .moveTo(-40.4, -6.8)
        .lineTo(-41.8, -3.2)
        .curveTo(-41.9, -2.8, -41.7, -2.4)
        .curveTo(-41.4, -2.1, -41, -2.1)
        .lineTo(-33.2, -2.1)
        .curveTo(-32.6, -2.1, -32.4, -2.7)
        .lineTo(-31.4, -6.3)
        .curveTo(-31.3, -6.7, -31.5, -7)
        .curveTo(-31.8, -7.3, -32.2, -7.3)
        .lineTo(-39.7, -7.3)
        .curveTo(-40.3, -7.3, -40.4, -6.8)
        .closePath()
        .moveTo(-52.3, -6.9)
        .lineTo(-54, -3.2)
        .curveTo(-54.2, -2.8, -54, -2.4)
        .curveTo(-53.7, -2.1, -53.3, -2.1)
        .lineTo(-45.5, -2.1)
        .curveTo(-44.9, -2.1, -44.8, -2.6)
        .lineTo(-43.4, -6.2)
        .curveTo(-43.2, -6.6, -43.5, -7)
        .curveTo(-43.7, -7.3, -44.1, -7.3)
        .lineTo(-51.6, -7.3)
        .curveTo(-52.1, -7.3, -52.3, -6.9)
        .closePath()
      this.shape_1.setTransform(119, 183.5)

      this.shape_2 = new cjs.Shape()
      this.shape_2.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-46.3, 109.7)
        .curveTo(-67.7, 100.6, -84.2, 84.2)
        .curveTo(-100.6, 67.7, -109.7, 46.3)
        .curveTo(-119, 24.2, -119, -0)
        .curveTo(-119, -24.2, -109.7, -46.3)
        .curveTo(-100.6, -67.7, -84.2, -84.2)
        .curveTo(-67.7, -100.6, -46.3, -109.7)
        .curveTo(-24.2, -119, 0, -119)
        .curveTo(24.2, -119, 46.3, -109.7)
        .curveTo(67.7, -100.6, 84.2, -84.2)
        .curveTo(100.6, -67.7, 109.7, -46.3)
        .curveTo(119, -24.2, 119, -0)
        .curveTo(119, 24.2, 109.7, 46.3)
        .curveTo(100.6, 67.7, 84.2, 84.2)
        .curveTo(67.7, 100.6, 46.3, 109.7)
        .curveTo(24.2, 119, 0, 119)
        .curveTo(-24.2, 119, -46.3, 109.7)
        .closePath()
      this.shape_2.setTransform(119, 119)

      this.addChild(this.shape_2, this.shape_1, this.shape)
    }).prototype = p = new cjs.Container()
  p.nominalBounds = new cjs.Rectangle(0, 0, 238.1, 238.1)

    ; (lib.Animation = function (mode, startPosition, loop) {
      this.initialize(mode, startPosition, loop, {})

      // timeline functions:
      this.frame_63 = function () {
        /* Stop at This Frame
The  timeline will stop/pause at the frame where you insert this code.
Can also be used to stop/pause the timeline of movieclips.
*/

        this.stop()
      }

      // actions tween:
      this.timeline.addTween(
        cjs.Tween.get(this)
          .wait(63)
          .call(this.frame_63)
          .wait(1)
      )

      // White outline
      this.instance = new lib.Whiteoutline()
      this.instance.setTransform(
        -1.5,
        -0.5,
        0.53,
        0.53,
        0,
        0,
        0,
        120.9,
        120.9
      )

      this.timeline.addTween(cjs.Tween.get(this.instance).wait(64))

      // Layer 4 (mask)
      var mask = new cjs.Shape()
      mask._off = true
      var mask_graphics_0 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_1 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_2 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_3 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_4 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_5 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_6 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_7 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_8 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_9 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_10 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_11 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_12 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_13 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_14 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_15 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_16 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_17 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_18 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_19 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_20 = new cjs.Graphics()
        .moveTo(-64.7, 68.1)
        .lineTo(-64.7, -68.1)
        .lineTo(64.7, -68.1)
        .lineTo(64.7, 68.1)
        .closePath()
      var mask_graphics_21 = new cjs.Graphics()
        .moveTo(-64.5, 68.1)
        .lineTo(-64.5, -68.1)
        .lineTo(64.5, -68.1)
        .lineTo(64.5, 68.1)
        .closePath()
      var mask_graphics_22 = new cjs.Graphics()
        .moveTo(-63.8, 68.1)
        .lineTo(-63.8, -68.1)
        .lineTo(63.8, -68.1)
        .lineTo(63.8, 68.1)
        .closePath()
      var mask_graphics_23 = new cjs.Graphics()
        .moveTo(-62.7, 68.1)
        .lineTo(-62.7, -68.1)
        .lineTo(62.7, -68.1)
        .lineTo(62.7, 68.1)
        .closePath()
      var mask_graphics_24 = new cjs.Graphics()
        .moveTo(-61.2, 68.1)
        .lineTo(-61.2, -68.1)
        .lineTo(61.2, -68.1)
        .lineTo(61.2, 68.1)
        .closePath()
      var mask_graphics_25 = new cjs.Graphics()
        .moveTo(-59.2, 68.1)
        .lineTo(-59.2, -68.1)
        .lineTo(59.2, -68.1)
        .lineTo(59.2, 68.1)
        .closePath()
      var mask_graphics_26 = new cjs.Graphics()
        .moveTo(-56.7, 68.1)
        .lineTo(-56.7, -68.1)
        .lineTo(56.8, -68.1)
        .lineTo(56.8, 68.1)
        .closePath()
      var mask_graphics_27 = new cjs.Graphics()
        .moveTo(-53.9, 68.1)
        .lineTo(-53.9, -68.1)
        .lineTo(53.9, -68.1)
        .lineTo(53.9, 68.1)
        .closePath()
      var mask_graphics_28 = new cjs.Graphics()
        .moveTo(-50.6, 68.1)
        .lineTo(-50.6, -68.1)
        .lineTo(50.5, -68.1)
        .lineTo(50.5, 68.1)
        .closePath()
      var mask_graphics_29 = new cjs.Graphics()
        .moveTo(-46.8, 68.1)
        .lineTo(-46.8, -68.1)
        .lineTo(46.8, -68.1)
        .lineTo(46.8, 68.1)
        .closePath()
      var mask_graphics_30 = new cjs.Graphics()
        .moveTo(-42.6, 68.1)
        .lineTo(-42.6, -68.1)
        .lineTo(42.6, -68.1)
        .lineTo(42.6, 68.1)
        .closePath()
      var mask_graphics_31 = new cjs.Graphics()
        .moveTo(-38, 68.1)
        .lineTo(-38, -68.1)
        .lineTo(38, -68.1)
        .lineTo(38, 68.1)
        .closePath()
      var mask_graphics_32 = new cjs.Graphics()
        .moveTo(-32.9, 68.1)
        .lineTo(-32.9, -68.1)
        .lineTo(32.9, -68.1)
        .lineTo(32.9, 68.1)
        .closePath()
      var mask_graphics_33 = new cjs.Graphics()
        .moveTo(-27.3, 68.1)
        .lineTo(-27.3, -68.1)
        .lineTo(27.4, -68.1)
        .lineTo(27.4, 68.1)
        .closePath()
      var mask_graphics_34 = new cjs.Graphics()
        .moveTo(-21.4, 68.1)
        .lineTo(-21.4, -68.1)
        .lineTo(21.4, -68.1)
        .lineTo(21.4, 68.1)
        .closePath()
      var mask_graphics_35 = new cjs.Graphics()
        .moveTo(-15, 68.1)
        .lineTo(-15, -68.1)
        .lineTo(15, -68.1)
        .lineTo(15, 68.1)
        .closePath()
      var mask_graphics_36 = new cjs.Graphics()
        .moveTo(-8.1, 68.1)
        .lineTo(-8.1, -68.1)
        .lineTo(8.2, -68.1)
        .lineTo(8.2, 68.1)
        .closePath()
      var mask_graphics_37 = new cjs.Graphics()
        .moveTo(-0.8, 68.1)
        .lineTo(-0.8, -68.1)
        .lineTo(0.8, -68.1)
        .lineTo(0.8, 68.1)
        .closePath()
      var mask_graphics_38 = new cjs.Graphics()
        .moveTo(-0.8, 68.1)
        .lineTo(-0.8, -68.1)
        .lineTo(0.8, -68.1)
        .lineTo(0.8, 68.1)
        .closePath()
      var mask_graphics_39 = new cjs.Graphics()
        .moveTo(-3.7, 68.1)
        .lineTo(-3.7, -68.1)
        .lineTo(3.7, -68.1)
        .lineTo(3.7, 68.1)
        .closePath()
      var mask_graphics_40 = new cjs.Graphics()
        .moveTo(-6.6, 68.1)
        .lineTo(-6.6, -68.1)
        .lineTo(6.6, -68.1)
        .lineTo(6.6, 68.1)
        .closePath()
      var mask_graphics_41 = new cjs.Graphics()
        .moveTo(-9.5, 68.1)
        .lineTo(-9.5, -68.1)
        .lineTo(9.5, -68.1)
        .lineTo(9.5, 68.1)
        .closePath()
      var mask_graphics_42 = new cjs.Graphics()
        .moveTo(-12.4, 68.1)
        .lineTo(-12.4, -68.1)
        .lineTo(12.4, -68.1)
        .lineTo(12.4, 68.1)
        .closePath()
      var mask_graphics_43 = new cjs.Graphics()
        .moveTo(-15.3, 68.1)
        .lineTo(-15.3, -68.1)
        .lineTo(15.3, -68.1)
        .lineTo(15.3, 68.1)
        .closePath()
      var mask_graphics_44 = new cjs.Graphics()
        .moveTo(-18.1, 68.1)
        .lineTo(-18.1, -68.1)
        .lineTo(18.1, -68.1)
        .lineTo(18.1, 68.1)
        .closePath()
      var mask_graphics_45 = new cjs.Graphics()
        .moveTo(-21, 68.1)
        .lineTo(-21, -68.1)
        .lineTo(21.1, -68.1)
        .lineTo(21.1, 68.1)
        .closePath()
      var mask_graphics_46 = new cjs.Graphics()
        .moveTo(-23.9, 68.1)
        .lineTo(-23.9, -68.1)
        .lineTo(23.9, -68.1)
        .lineTo(23.9, 68.1)
        .closePath()
      var mask_graphics_47 = new cjs.Graphics()
        .moveTo(-26.8, 68.1)
        .lineTo(-26.8, -68.1)
        .lineTo(26.8, -68.1)
        .lineTo(26.8, 68.1)
        .closePath()
      var mask_graphics_48 = new cjs.Graphics()
        .moveTo(-29.7, 68.1)
        .lineTo(-29.7, -68.1)
        .lineTo(29.7, -68.1)
        .lineTo(29.7, 68.1)
        .closePath()
      var mask_graphics_49 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_50 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_51 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_52 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_53 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_54 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_55 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_56 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_57 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_58 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_59 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_60 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_61 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_62 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()
      var mask_graphics_63 = new cjs.Graphics()
        .moveTo(-32.6, 68.1)
        .lineTo(-32.6, -68.1)
        .lineTo(32.6, -68.1)
        .lineTo(32.6, 68.1)
        .closePath()

      this.timeline.addTween(
        cjs.Tween.get(mask)
          .to({ graphics: mask_graphics_0, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_1, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_2, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_3, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_4, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_5, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_6, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_7, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_8, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_9, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_10, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_11, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_12, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_13, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_14, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_15, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_16, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_17, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_18, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_19, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_20, x: -0.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_21, x: -0.7, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_22, x: -0.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_23, x: 1.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_24, x: 2.6, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_25, x: 4.6, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_26, x: 7.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_27, x: 10, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_28, x: 13.3, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_29, x: 17.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_30, x: 21.4, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_31, x: 26, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_32, x: 31.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_33, x: 36.7, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_34, x: 42.7, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_35, x: 49.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_36, x: 56.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_37, x: 63.5, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_38, x: 63.5, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_39, x: 60.5, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_40, x: 57.6, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_41, x: 54.7, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_42, x: 51.7, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_43, x: 48.8, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_44, x: 45.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_45, x: 42.9, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_46, x: 40, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_47, x: 37.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_48, x: 34.1, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_49, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_50, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_51, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_52, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_53, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_54, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_55, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_56, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_57, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_58, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_59, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_60, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_61, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_62, x: 31.2, y: 0 })
          .wait(1)
          .to({ graphics: mask_graphics_63, x: 31.2, y: 0 })
          .wait(1)
      )

      // Computer
      this.instance_1 = new lib.computerwhite()
      this.instance_1.setTransform(
        -1.5,
        -0.5,
        0.522,
        0.523,
        0,
        0,
        0,
        119,
        119
      )
      this.instance_1.alpha = 0

      this.instance_1.mask = mask

      this.timeline.addTween(
        cjs.Tween.get(this.instance_1)
          .to({ alpha: 1 }, 5)
          .wait(59)
      )

      // Transparent computer
      this.shape = new cjs.Shape()
      this.shape.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-1.4, 2.1)
        .lineTo(-1.4, -2.1)
        .lineTo(1.4, -2.1)
        .lineTo(1.4, 2.1)
        .closePath()
      this.shape.setTransform(0.8, 38.3, 0.534, 0.501)

      this.shape_1 = new cjs.Shape()
      this.shape_1.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(3.2, 4.3)
        .curveTo(2.5, 4, 2.2, 3.2)
        .lineTo(1.1, 0.3)
        .lineTo(1.1, 0.3)
        .lineTo(0.8, -0.1)
        .curveTo(0.6, -0.3, 0.3, -0.4)
        .lineTo(-0.2, -0.4)
        .lineTo(-4, -0.4)
        .curveTo(-4.8, -0.4, -5.3, -1)
        .curveTo(-5.9, -1.6, -5.9, -2.5)
        .curveTo(-5.9, -3.3, -5.3, -3.9)
        .curveTo(-4.8, -4.5, -4, -4.5)
        .lineTo(0.5, -4.4)
        .curveTo(1.6, -4.3, 2.5, -3.8)
        .curveTo(3.8, -3, 4.5, -1.4)
        .lineTo(5.7, 1.6)
        .curveTo(6, 2.4, 5.7, 3.2)
        .curveTo(5.4, 4, 4.7, 4.3)
        .curveTo(4.3, 4.5, 4, 4.5)
        .curveTo(3.6, 4.5, 3.2, 4.3)
        .closePath()
      this.shape_1.setTransform(29.6, 30.1, 0.534, 0.501)

      this.shape_2 = new cjs.Shape()
      this.shape_2.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-5.7, 5.3)
        .curveTo(-6.4, 5.3, -7, 4.7)
        .curveTo(-7.5, 4.2, -7.6, 3.3)
        .curveTo(-7.5, 2.5, -7, 1.9)
        .curveTo(-6.4, 1.3, -5.7, 1.3)
        .lineTo(1.2, 1.3)
        .lineTo(3, 1.1)
        .lineTo(3.7, 0.9)
        .lineTo(3.8, 0.8)
        .lineTo(3.8, 0.7)
        .lineTo(2.6, -2.5)
        .curveTo(2.3, -3.3, 2.6, -4)
        .curveTo(3, -4.8, 3.7, -5.2)
        .curveTo(4.3, -5.5, 5.1, -5.2)
        .curveTo(5.8, -4.9, 6.1, -4.1)
        .lineTo(6.8, -2.3)
        .curveTo(7.3, -1.1, 7.5, 0.1)
        .lineTo(7.6, 0.8)
        .curveTo(7.5, 1.9, 7.1, 2.8)
        .curveTo(6.8, 3.5, 6.1, 4)
        .curveTo(5.2, 4.8, 4, 5.1)
        .curveTo(2.9, 5.3, 1.2, 5.3)
        .closePath()
      this.shape_2.setTransform(31.1, 36.7, 0.534, 0.501)

      this.shape_3 = new cjs.Shape()
      this.shape_3.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-23.1, 1.5)
        .curveTo(-23.7, 0.9, -23.7, 0)
        .curveTo(-23.7, -0.8, -23.1, -1.4)
        .curveTo(-22.5, -2, -21.8, -2)
        .lineTo(-12.3, -2)
        .curveTo(-11.5, -2, -11, -1.4)
        .curveTo(-10.4, -0.8, -10.4, 0)
        .curveTo(-10.4, 0.9, -11, 1.4)
        .curveTo(-11.5, 2, -12.3, 2)
        .lineTo(-21.8, 2.1)
        .curveTo(-22.5, 2.1, -23.1, 1.5)
        .closePath()
        .moveTo(12.3, 2)
        .curveTo(11.5, 2, 11, 1.4)
        .curveTo(10.4, 0.8, 10.4, -0)
        .curveTo(10.4, -0.9, 11, -1.5)
        .curveTo(11.5, -2.1, 12.3, -2.1)
        .lineTo(21.8, -2.1)
        .curveTo(22.5, -2.1, 23.1, -1.5)
        .curveTo(23.6, -0.9, 23.7, -0)
        .curveTo(23.6, 0.8, 23.1, 1.4)
        .curveTo(22.5, 2, 21.8, 2)
        .closePath()
        .moveTo(-4.8, 2)
        .curveTo(-5.5, 2, -6, 1.4)
        .curveTo(-6.6, 0.9, -6.6, 0)
        .curveTo(-6.6, -0.8, -6, -1.4)
        .curveTo(-5.5, -2, -4.8, -2)
        .lineTo(4.7, -2)
        .curveTo(5.5, -2, 6, -1.4)
        .curveTo(6.6, -0.8, 6.6, -0)
        .curveTo(6.6, 0.8, 6, 1.4)
        .curveTo(5.5, 2, 4.7, 2)
        .closePath()
      this.shape_3.setTransform(12.2, 29, 0.534, 0.501)

      this.shape_4 = new cjs.Shape()
      this.shape_4.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-4.8, 2.1)
        .curveTo(-5.5, 2, -6, 1.5)
        .curveTo(-6.6, 0.8, -6.6, 0)
        .curveTo(-6.6, -0.9, -6, -1.4)
        .curveTo(-5.5, -2.1, -4.8, -2.1)
        .lineTo(4.7, -2.1)
        .curveTo(5.5, -2.1, 6, -1.4)
        .curveTo(6.6, -0.9, 6.6, 0)
        .curveTo(6.6, 0.8, 6, 1.5)
        .curveTo(5.5, 2, 4.7, 2.1)
        .closePath()
        .moveTo(-21.8, 2.1)
        .curveTo(-22.5, 2, -23.1, 1.5)
        .curveTo(-23.7, 0.8, -23.7, 0)
        .curveTo(-23.7, -0.8, -23.1, -1.4)
        .curveTo(-22.5, -2, -21.8, -2)
        .lineTo(-12.3, -2.1)
        .curveTo(-11.5, -2.1, -11, -1.4)
        .curveTo(-10.4, -0.9, -10.4, 0)
        .curveTo(-10.4, 0.8, -11, 1.5)
        .curveTo(-11.5, 2, -12.3, 2.1)
        .closePath()
        .moveTo(12.3, 2)
        .curveTo(11.5, 2, 11, 1.4)
        .curveTo(10.4, 0.8, 10.4, 0)
        .curveTo(10.4, -0.9, 11, -1.4)
        .curveTo(11.5, -2.1, 12.3, -2.1)
        .lineTo(21.8, -2.1)
        .curveTo(22.5, -2.1, 23.1, -1.4)
        .curveTo(23.6, -0.9, 23.7, -0.1)
        .curveTo(23.6, 0.8, 23.1, 1.4)
        .curveTo(22.5, 2, 21.8, 2)
        .closePath()
      this.shape_4.setTransform(12.2, 38.3, 0.534, 0.501)

      this.shape_5 = new cjs.Shape()
      this.shape_5.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(28.9, 54.7)
        .curveTo(28.3, 54.2, 28.3, 53.4)
        .curveTo(28.3, 52.6, 28.8, 52.1)
        .curveTo(29.3, 51.5, 30, 51.5)
        .curveTo(30.7, 51.5, 31.2, 50.9)
        .curveTo(31.7, 50.3, 31.7, 49.6)
        .lineTo(31.7, 46)
        .curveTo(31.7, 45.2, 32.3, 44.6)
        .curveTo(32.8, 44.1, 33.5, 44.1)
        .curveTo(34.2, 44.1, 34.8, 44.6)
        .curveTo(35.3, 45.2, 35.3, 46)
        .lineTo(35.3, 49.6)
        .curveTo(35.3, 51.9, 33.8, 53.5)
        .curveTo(32.3, 55.2, 30.1, 55.2)
        .curveTo(29.4, 55.2, 28.9, 54.7)
        .closePath()
        .moveTo(14.2, 55.2)
        .curveTo(13.4, 55.2, 12.9, 54.7)
        .curveTo(12.4, 54.2, 12.4, 53.3)
        .curveTo(12.4, 52.5, 12.9, 52.1)
        .curveTo(13.4, 51.5, 14.2, 51.5)
        .lineTo(23, 51.5)
        .curveTo(23.7, 51.5, 24.3, 52.1)
        .curveTo(24.8, 52.5, 24.8, 53.3)
        .curveTo(24.8, 54.2, 24.3, 54.7)
        .curveTo(23.7, 55.2, 23, 55.2)
        .closePath()
        .moveTo(-1.7, 55.2)
        .curveTo(-2.5, 55.2, -3, 54.7)
        .curveTo(-3.5, 54.2, -3.5, 53.3)
        .curveTo(-3.5, 52.5, -3, 52.1)
        .curveTo(-2.5, 51.5, -1.7, 51.5)
        .lineTo(7.1, 51.5)
        .curveTo(7.8, 51.5, 8.4, 52.1)
        .curveTo(8.9, 52.5, 8.9, 53.3)
        .curveTo(8.9, 54.2, 8.4, 54.7)
        .curveTo(7.8, 55.2, 7.1, 55.2)
        .closePath()
        .moveTo(-17.6, 55.2)
        .curveTo(-18.4, 55.2, -18.9, 54.7)
        .curveTo(-19.4, 54.2, -19.4, 53.3)
        .curveTo(-19.4, 52.5, -18.9, 52.1)
        .curveTo(-18.4, 51.5, -17.6, 51.5)
        .lineTo(-8.8, 51.5)
        .curveTo(-8.1, 51.5, -7.5, 52.1)
        .curveTo(-7, 52.5, -7, 53.3)
        .curveTo(-7, 54.2, -7.5, 54.7)
        .curveTo(-8.1, 55.2, -8.8, 55.2)
        .closePath()
        .moveTo(-33.5, 55.2)
        .curveTo(-34.3, 55.2, -34.8, 54.7)
        .curveTo(-35.3, 54.2, -35.3, 53.3)
        .curveTo(-35.3, 52.5, -34.8, 52.1)
        .curveTo(-34.3, 51.5, -33.5, 51.5)
        .lineTo(-24.7, 51.5)
        .curveTo(-24, 51.5, -23.4, 52.1)
        .curveTo(-22.9, 52.5, -22.9, 53.3)
        .curveTo(-22.9, 54.2, -23.4, 54.7)
        .curveTo(-24, 55.2, -24.7, 55.2)
        .closePath()
        .moveTo(32.3, 39.8)
        .curveTo(31.7, 39.2, 31.7, 38.5)
        .lineTo(31.7, 29)
        .curveTo(31.7, 28.3, 32.3, 27.7)
        .curveTo(32.8, 27.2, 33.5, 27.1)
        .curveTo(34.2, 27.2, 34.8, 27.7)
        .curveTo(35.3, 28.3, 35.3, 29)
        .lineTo(35.3, 38.5)
        .curveTo(35.3, 39.2, 34.8, 39.8)
        .curveTo(34.2, 40.4, 33.5, 40.4)
        .curveTo(32.8, 40.4, 32.3, 39.8)
        .closePath()
        .moveTo(32.3, 22.8)
        .curveTo(31.7, 22.3, 31.7, 21.5)
        .lineTo(31.7, 12.1)
        .curveTo(31.7, 11.3, 32.3, 10.8)
        .curveTo(32.8, 10.3, 33.5, 10.2)
        .curveTo(34.2, 10.3, 34.8, 10.8)
        .curveTo(35.3, 11.3, 35.3, 12.1)
        .lineTo(35.3, 21.5)
        .curveTo(35.3, 22.3, 34.8, 22.8)
        .curveTo(34.2, 23.4, 33.5, 23.4)
        .curveTo(32.8, 23.4, 32.3, 22.8)
        .closePath()
        .moveTo(32.3, 5.9)
        .curveTo(31.7, 5.3, 31.7, 4.6)
        .lineTo(31.7, -4.9)
        .curveTo(31.7, -5.6, 32.3, -6.2)
        .curveTo(32.8, -6.7, 33.5, -6.7)
        .curveTo(34.2, -6.7, 34.8, -6.2)
        .curveTo(35.3, -5.6, 35.3, -4.9)
        .lineTo(35.3, 4.6)
        .curveTo(35.3, 5.3, 34.8, 5.9)
        .curveTo(34.2, 6.4, 33.5, 6.4)
        .curveTo(32.8, 6.4, 32.3, 5.9)
        .closePath()
        .moveTo(32.3, -11)
        .curveTo(31.7, -11.6, 31.7, -12.4)
        .lineTo(31.7, -21.8)
        .curveTo(31.7, -22.5, 32.3, -23.1)
        .curveTo(32.8, -23.7, 33.5, -23.6)
        .curveTo(34.2, -23.7, 34.8, -23.1)
        .curveTo(35.3, -22.5, 35.3, -21.8)
        .lineTo(35.3, -12.4)
        .curveTo(35.3, -11.6, 34.8, -11)
        .curveTo(34.2, -10.5, 33.5, -10.5)
        .curveTo(32.8, -10.5, 32.3, -11)
        .closePath()
        .moveTo(32.3, -27.9)
        .curveTo(31.7, -28.5, 31.7, -29.3)
        .lineTo(31.7, -38.7)
        .curveTo(31.7, -39.5, 32.3, -40.1)
        .curveTo(32.8, -40.6, 33.5, -40.6)
        .curveTo(34.2, -40.6, 34.8, -40.1)
        .curveTo(35.3, -39.5, 35.3, -38.7)
        .lineTo(35.3, -29.3)
        .curveTo(35.3, -28.5, 34.8, -27.9)
        .curveTo(34.2, -27.4, 33.5, -27.4)
        .curveTo(32.8, -27.4, 32.3, -27.9)
        .closePath()
        .moveTo(32.3, -44.9)
        .curveTo(31.7, -45.4, 31.7, -46.3)
        .lineTo(31.7, -49.6)
        .curveTo(31.7, -50.4, 31.2, -51)
        .curveTo(30.7, -51.5, 30, -51.5)
        .lineTo(29.8, -51.5)
        .curveTo(29.1, -51.5, 28.6, -52.1)
        .curveTo(28.1, -52.6, 28.1, -53.3)
        .curveTo(28.1, -54.2, 28.6, -54.7)
        .curveTo(29.1, -55.3, 29.8, -55.3)
        .lineTo(30, -55.3)
        .curveTo(32.2, -55.2, 33.7, -53.6)
        .curveTo(35.3, -52, 35.3, -49.6)
        .lineTo(35.3, -46.3)
        .curveTo(35.3, -45.4, 34.8, -44.9)
        .curveTo(34.2, -44.3, 33.5, -44.3)
        .curveTo(32.8, -44.3, 32.3, -44.9)
        .closePath()
        .moveTo(13.9, -51.5)
        .curveTo(13.2, -51.5, 12.7, -52.1)
        .curveTo(12.2, -52.6, 12.2, -53.3)
        .curveTo(12.2, -54.2, 12.7, -54.7)
        .curveTo(13.2, -55.3, 13.9, -55.3)
        .lineTo(22.8, -55.3)
        .curveTo(23.5, -55.3, 24, -54.7)
        .curveTo(24.5, -54.2, 24.5, -53.3)
        .curveTo(24.5, -52.6, 24, -52.1)
        .curveTo(23.5, -51.5, 22.8, -51.5)
        .closePath()
        .moveTo(-2, -51.5)
        .curveTo(-2.7, -51.5, -3.2, -52.1)
        .curveTo(-3.7, -52.6, -3.7, -53.3)
        .curveTo(-3.7, -54.2, -3.2, -54.7)
        .curveTo(-2.7, -55.3, -2, -55.3)
        .lineTo(6.9, -55.3)
        .curveTo(7.6, -55.3, 8.1, -54.7)
        .curveTo(8.6, -54.2, 8.6, -53.3)
        .curveTo(8.6, -52.6, 8.1, -52.1)
        .curveTo(7.6, -51.5, 6.9, -51.5)
        .closePath()
        .moveTo(-17.9, -51.5)
        .curveTo(-18.6, -51.5, -19.1, -52.1)
        .curveTo(-19.6, -52.6, -19.6, -53.3)
        .curveTo(-19.6, -54.2, -19.1, -54.7)
        .curveTo(-18.6, -55.3, -17.9, -55.3)
        .lineTo(-9, -55.3)
        .curveTo(-8.3, -55.3, -7.8, -54.7)
        .curveTo(-7.3, -54.2, -7.3, -53.3)
        .curveTo(-7.3, -52.6, -7.8, -52.1)
        .curveTo(-8.3, -51.5, -9, -51.5)
        .closePath()
        .moveTo(-32.9, -51.5)
        .curveTo(-33.6, -51.5, -34.1, -52.1)
        .curveTo(-34.7, -52.6, -34.7, -53.3)
        .curveTo(-34.7, -54.2, -34.1, -54.7)
        .curveTo(-33.6, -55.3, -32.9, -55.3)
        .lineTo(-24.9, -55.3)
        .curveTo(-24.2, -55.3, -23.7, -54.7)
        .curveTo(-23.2, -54.2, -23.2, -53.3)
        .curveTo(-23.2, -52.6, -23.7, -52.1)
        .curveTo(-24.2, -51.5, -24.9, -51.5)
        .closePath()
      this.shape_5.setTransform(17.8, -6, 0.53, 0.53)

      this.shape_6 = new cjs.Shape()
      this.shape_6.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-1.4, 2.1)
        .lineTo(-1.4, -2.1)
        .lineTo(1.4, -2.1)
        .lineTo(1.4, 2.1)
        .closePath()
      this.shape_6.setTransform(-3.9, 38.3, 0.534, 0.501)

      this.shape_7 = new cjs.Shape()
      this.shape_7.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-4.7, 4.3)
        .curveTo(-5.4, 4, -5.7, 3.2)
        .curveTo(-6, 2.4, -5.7, 1.6)
        .lineTo(-4.5, -1.4)
        .curveTo(-3.8, -3, -2.5, -3.8)
        .curveTo(-1.6, -4.3, -0.5, -4.4)
        .lineTo(0.3, -4.5)
        .lineTo(4, -4.5)
        .curveTo(4.8, -4.5, 5.3, -3.9)
        .curveTo(5.9, -3.3, 5.9, -2.5)
        .curveTo(5.9, -1.6, 5.3, -1)
        .curveTo(4.8, -0.4, 4, -0.4)
        .lineTo(0.3, -0.4)
        .lineTo(-0.3, -0.4)
        .curveTo(-0.6, -0.3, -0.8, -0.1)
        .lineTo(-1.1, 0.3)
        .lineTo(-1.1, 0.3)
        .lineTo(-2.2, 3.2)
        .curveTo(-2.5, 4, -3.2, 4.3)
        .curveTo(-3.6, 4.5, -4, 4.5)
        .curveTo(-4.3, 4.5, -4.7, 4.3)
        .closePath()
      this.shape_7.setTransform(-32.8, 30.1, 0.534, 0.501)

      this.shape_8 = new cjs.Shape()
      this.shape_8.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(-1.1, 5.3)
        .curveTo(-3, 5.3, -4, 5.1)
        .curveTo(-5.2, 4.8, -6.1, 4)
        .curveTo(-6.7, 3.5, -7.1, 2.8)
        .curveTo(-7.5, 1.9, -7.5, 0.8)
        .lineTo(-7.5, 0.1)
        .curveTo(-7.3, -1, -6.8, -2.3)
        .lineTo(-6.1, -4.1)
        .curveTo(-5.8, -4.9, -5.1, -5.2)
        .curveTo(-4.4, -5.5, -3.7, -5.2)
        .curveTo(-2.9, -4.8, -2.6, -4)
        .curveTo(-2.3, -3.2, -2.7, -2.5)
        .lineTo(-3.3, -0.7)
        .curveTo(-3.7, 0, -3.8, 0.7)
        .lineTo(-3.8, 0.8)
        .lineTo(-3.7, 0.9)
        .lineTo(-3, 1.1)
        .lineTo(-1.1, 1.3)
        .lineTo(5.6, 1.3)
        .curveTo(6.4, 1.3, 7, 1.9)
        .curveTo(7.5, 2.5, 7.5, 3.3)
        .curveTo(7.5, 4.2, 7, 4.7)
        .curveTo(6.4, 5.3, 5.6, 5.3)
        .closePath()
      this.shape_8.setTransform(-34.2, 36.7, 0.534, 0.501)

      this.shape_9 = new cjs.Shape()
      this.shape_9.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(12.3, 2)
        .curveTo(11.5, 2, 11, 1.4)
        .curveTo(10.4, 0.9, 10.4, 0)
        .curveTo(10.4, -0.8, 11, -1.4)
        .curveTo(11.5, -2, 12.3, -2)
        .lineTo(21.8, -2)
        .curveTo(22.5, -2, 23.1, -1.4)
        .curveTo(23.6, -0.8, 23.6, 0)
        .curveTo(23.6, 0.9, 23.1, 1.5)
        .curveTo(22.5, 2.1, 21.7, 2.1)
        .closePath()
        .moveTo(-4.7, 2)
        .curveTo(-5.5, 2, -6.1, 1.4)
        .curveTo(-6.6, 0.8, -6.6, -0)
        .curveTo(-6.6, -0.8, -6.1, -1.4)
        .curveTo(-5.5, -2, -4.7, -2)
        .lineTo(4.7, -2)
        .curveTo(5.5, -2, 6.1, -1.4)
        .curveTo(6.6, -0.8, 6.6, 0)
        .curveTo(6.6, 0.9, 6.1, 1.4)
        .curveTo(5.5, 2, 4.7, 2)
        .closePath()
        .moveTo(-21.7, 2)
        .curveTo(-22.5, 2, -23.1, 1.4)
        .curveTo(-23.6, 0.8, -23.6, -0)
        .curveTo(-23.6, -0.9, -23.1, -1.5)
        .curveTo(-22.5, -2.1, -21.7, -2.1)
        .lineTo(-12.3, -2.1)
        .curveTo(-11.5, -2.1, -10.9, -1.5)
        .curveTo(-10.4, -0.9, -10.4, -0)
        .curveTo(-10.4, 0.8, -10.9, 1.4)
        .curveTo(-11.5, 2, -12.3, 2)
        .closePath()
      this.shape_9.setTransform(-15.3, 29, 0.534, 0.501)

      this.shape_10 = new cjs.Shape()
      this.shape_10.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(12.3, 2.1)
        .curveTo(11.5, 2, 11, 1.5)
        .curveTo(10.4, 0.8, 10.4, 0)
        .curveTo(10.4, -0.9, 11, -1.4)
        .curveTo(11.5, -2.1, 12.3, -2.1)
        .lineTo(21.8, -2)
        .curveTo(22.5, -2, 23.1, -1.4)
        .curveTo(23.6, -0.8, 23.6, 0)
        .curveTo(23.6, 0.8, 23.1, 1.5)
        .curveTo(22.5, 2, 21.7, 2.1)
        .closePath()
        .moveTo(-4.7, 2.1)
        .curveTo(-5.5, 2, -6.1, 1.5)
        .curveTo(-6.6, 0.8, -6.6, 0)
        .curveTo(-6.6, -0.9, -6.1, -1.4)
        .curveTo(-5.5, -2.1, -4.7, -2.1)
        .lineTo(4.7, -2.1)
        .curveTo(5.5, -2.1, 6.1, -1.4)
        .curveTo(6.6, -0.9, 6.6, 0)
        .curveTo(6.6, 0.8, 6.1, 1.5)
        .curveTo(5.5, 2, 4.7, 2.1)
        .closePath()
        .moveTo(-21.7, 2)
        .curveTo(-22.5, 2, -23.1, 1.4)
        .curveTo(-23.6, 0.8, -23.6, -0.1)
        .curveTo(-23.6, -0.9, -23.1, -1.4)
        .curveTo(-22.5, -2.1, -21.7, -2.1)
        .lineTo(-12.3, -2.1)
        .curveTo(-11.5, -2.1, -10.9, -1.4)
        .curveTo(-10.4, -0.9, -10.4, 0)
        .curveTo(-10.4, 0.8, -10.9, 1.4)
        .curveTo(-11.5, 2, -12.3, 2)
        .closePath()
      this.shape_10.setTransform(-15.3, 38.3, 0.534, 0.501)

      this.shape_11 = new cjs.Shape()
      this.shape_11.graphics
        .beginFill('#FFFFFF')
        .beginStroke()
        .moveTo(24.7, 55.2)
        .curveTo(23.9, 55.2, 23.4, 54.7)
        .curveTo(23, 54.2, 22.9, 53.3)
        .curveTo(23, 52.5, 23.4, 52.1)
        .curveTo(23.9, 51.5, 24.7, 51.5)
        .lineTo(33.5, 51.5)
        .curveTo(34.3, 51.5, 34.8, 52.1)
        .curveTo(35.3, 52.5, 35.3, 53.3)
        .curveTo(35.3, 54.2, 34.8, 54.7)
        .curveTo(34.3, 55.2, 33.5, 55.2)
        .closePath()
        .moveTo(8.8, 55.2)
        .curveTo(8.1, 55.2, 7.5, 54.7)
        .curveTo(7.1, 54.2, 7.1, 53.3)
        .curveTo(7.1, 52.5, 7.5, 52.1)
        .curveTo(8.1, 51.5, 8.8, 51.5)
        .lineTo(17.7, 51.5)
        .curveTo(18.3, 51.5, 18.9, 52.1)
        .curveTo(19.4, 52.5, 19.4, 53.3)
        .curveTo(19.4, 54.2, 18.9, 54.7)
        .curveTo(18.3, 55.2, 17.7, 55.2)
        .closePath()
        .moveTo(-7.1, 55.2)
        .curveTo(-7.9, 55.2, -8.3, 54.7)
        .curveTo(-8.9, 54.2, -8.9, 53.3)
        .curveTo(-8.9, 52.5, -8.3, 52.1)
        .curveTo(-7.9, 51.5, -7.1, 51.5)
        .lineTo(1.7, 51.5)
        .curveTo(2.4, 51.5, 3, 52.1)
        .curveTo(3.5, 52.5, 3.5, 53.3)
        .curveTo(3.5, 54.2, 3, 54.7)
        .curveTo(2.4, 55.2, 1.7, 55.2)
        .closePath()
        .moveTo(-23, 55.2)
        .curveTo(-23.8, 55.2, -24.3, 54.7)
        .curveTo(-24.8, 54.2, -24.8, 53.3)
        .curveTo(-24.8, 52.5, -24.3, 52.1)
        .curveTo(-23.8, 51.5, -23, 51.5)
        .lineTo(-14.2, 51.5)
        .curveTo(-13.4, 51.5, -12.9, 52.1)
        .curveTo(-12.4, 52.5, -12.4, 53.3)
        .curveTo(-12.4, 54.2, -12.9, 54.7)
        .curveTo(-13.4, 55.2, -14.2, 55.2)
        .closePath()
        .moveTo(-33.8, 53.5)
        .curveTo(-35.3, 51.9, -35.3, 49.6)
        .lineTo(-35.3, 46)
        .curveTo(-35.3, 45.2, -34.8, 44.6)
        .curveTo(-34.2, 44.1, -33.5, 44.1)
        .curveTo(-32.8, 44.1, -32.3, 44.6)
        .curveTo(-31.8, 45.2, -31.7, 46)
        .lineTo(-31.7, 49.6)
        .curveTo(-31.7, 50.3, -31.3, 50.9)
        .curveTo(-30.7, 51.5, -30, 51.5)
        .curveTo(-29.3, 51.5, -28.8, 52.1)
        .curveTo(-28.3, 52.6, -28.3, 53.4)
        .curveTo(-28.4, 54.2, -28.8, 54.7)
        .curveTo(-29.4, 55.2, -30.1, 55.2)
        .curveTo(-32.3, 55.2, -33.8, 53.5)
        .closePath()
        .moveTo(-34.8, 39.8)
        .curveTo(-35.3, 39.2, -35.3, 38.5)
        .lineTo(-35.3, 29)
        .curveTo(-35.3, 28.3, -34.8, 27.7)
        .curveTo(-34.2, 27.2, -33.5, 27.1)
        .curveTo(-32.8, 27.2, -32.3, 27.7)
        .curveTo(-31.8, 28.3, -31.7, 29)
        .lineTo(-31.7, 38.5)
        .curveTo(-31.8, 39.2, -32.3, 39.8)
        .curveTo(-32.8, 40.4, -33.5, 40.4)
        .curveTo(-34.2, 40.4, -34.8, 39.8)
        .closePath()
        .moveTo(-34.8, 22.8)
        .curveTo(-35.3, 22.3, -35.3, 21.5)
        .lineTo(-35.3, 12.1)
        .curveTo(-35.3, 11.3, -34.8, 10.8)
        .curveTo(-34.2, 10.3, -33.5, 10.2)
        .curveTo(-32.8, 10.3, -32.3, 10.8)
        .curveTo(-31.8, 11.3, -31.7, 12.1)
        .lineTo(-31.7, 21.5)
        .curveTo(-31.8, 22.3, -32.3, 22.8)
        .curveTo(-32.8, 23.4, -33.5, 23.4)
        .curveTo(-34.2, 23.4, -34.8, 22.8)
        .closePath()
        .moveTo(-34.8, 5.9)
        .curveTo(-35.3, 5.3, -35.3, 4.6)
        .lineTo(-35.3, -4.9)
        .curveTo(-35.3, -5.6, -34.8, -6.2)
        .curveTo(-34.2, -6.7, -33.5, -6.7)
        .curveTo(-32.8, -6.7, -32.3, -6.2)
        .curveTo(-31.8, -5.6, -31.7, -4.9)
        .lineTo(-31.7, 4.6)
        .curveTo(-31.8, 5.3, -32.3, 5.9)
        .curveTo(-32.8, 6.4, -33.5, 6.4)
        .curveTo(-34.2, 6.4, -34.8, 5.9)
        .closePath()
        .moveTo(-34.8, -11)
        .curveTo(-35.3, -11.6, -35.3, -12.4)
        .lineTo(-35.3, -21.8)
        .curveTo(-35.3, -22.5, -34.8, -23.1)
        .curveTo(-34.2, -23.7, -33.5, -23.6)
        .curveTo(-32.8, -23.7, -32.3, -23.1)
        .curveTo(-31.8, -22.5, -31.7, -21.8)
        .lineTo(-31.7, -12.4)
        .curveTo(-31.8, -11.6, -32.3, -11)
        .curveTo(-32.8, -10.5, -33.5, -10.5)
        .curveTo(-34.2, -10.5, -34.8, -11)
        .closePath()
        .moveTo(-34.8, -27.9)
        .curveTo(-35.3, -28.5, -35.3, -29.3)
        .lineTo(-35.3, -38.7)
        .curveTo(-35.3, -39.5, -34.8, -40.1)
        .curveTo(-34.2, -40.6, -33.5, -40.6)
        .curveTo(-32.8, -40.6, -32.3, -40.1)
        .curveTo(-31.8, -39.5, -31.7, -38.7)
        .lineTo(-31.7, -29.3)
        .curveTo(-31.8, -28.5, -32.3, -27.9)
        .curveTo(-32.8, -27.4, -33.5, -27.4)
        .curveTo(-34.2, -27.4, -34.8, -27.9)
        .closePath()
        .moveTo(-34.8, -44.9)
        .curveTo(-35.3, -45.4, -35.3, -46.3)
        .lineTo(-35.3, -49.6)
        .curveTo(-35.3, -52, -33.8, -53.6)
        .curveTo(-32.2, -55.2, -30, -55.3)
        .lineTo(-29.8, -55.3)
        .curveTo(-29.1, -55.3, -28.6, -54.7)
        .curveTo(-28.1, -54.2, -28.1, -53.3)
        .curveTo(-28.1, -52.6, -28.6, -52.1)
        .curveTo(-29.1, -51.5, -29.8, -51.5)
        .lineTo(-30, -51.5)
        .curveTo(-30.7, -51.5, -31.3, -51)
        .curveTo(-31.7, -50.4, -31.7, -49.6)
        .lineTo(-31.7, -46.3)
        .curveTo(-31.8, -45.4, -32.3, -44.9)
        .curveTo(-32.8, -44.3, -33.5, -44.3)
        .curveTo(-34.2, -44.3, -34.8, -44.9)
        .closePath()
        .moveTo(24.9, -51.5)
        .curveTo(24.2, -51.5, 23.6, -52.1)
        .curveTo(23.2, -52.6, 23.2, -53.3)
        .curveTo(23.2, -54.2, 23.6, -54.7)
        .curveTo(24.2, -55.3, 24.9, -55.3)
        .lineTo(32.9, -55.3)
        .curveTo(33.6, -55.3, 34.2, -54.7)
        .curveTo(34.6, -54.2, 34.6, -53.3)
        .curveTo(34.6, -52.6, 34.2, -52.1)
        .curveTo(33.6, -51.5, 32.9, -51.5)
        .closePath()
        .moveTo(9, -51.5)
        .curveTo(8.3, -51.5, 7.8, -52.1)
        .curveTo(7.2, -52.6, 7.2, -53.3)
        .curveTo(7.2, -54.2, 7.8, -54.7)
        .curveTo(8.3, -55.3, 9, -55.3)
        .lineTo(17.9, -55.3)
        .curveTo(18.6, -55.3, 19.1, -54.7)
        .curveTo(19.6, -54.2, 19.6, -53.3)
        .curveTo(19.6, -52.6, 19.1, -52.1)
        .curveTo(18.6, -51.5, 17.9, -51.5)
        .closePath()
        .moveTo(-6.9, -51.5)
        .curveTo(-7.6, -51.5, -8.1, -52.1)
        .curveTo(-8.6, -52.6, -8.7, -53.3)
        .curveTo(-8.6, -54.2, -8.1, -54.7)
        .curveTo(-7.6, -55.3, -6.9, -55.3)
        .lineTo(1.9, -55.3)
        .curveTo(2.7, -55.3, 3.2, -54.7)
        .curveTo(3.7, -54.2, 3.7, -53.3)
        .curveTo(3.7, -52.6, 3.2, -52.1)
        .curveTo(2.7, -51.5, 1.9, -51.5)
        .closePath()
        .moveTo(-22.8, -51.5)
        .curveTo(-23.5, -51.5, -24.1, -52.1)
        .curveTo(-24.5, -52.6, -24.5, -53.3)
        .curveTo(-24.5, -54.2, -24.1, -54.7)
        .curveTo(-23.5, -55.3, -22.8, -55.3)
        .lineTo(-14, -55.3)
        .curveTo(-13.2, -55.3, -12.7, -54.7)
        .curveTo(-12.2, -54.2, -12.2, -53.3)
        .curveTo(-12.2, -52.6, -12.7, -52.1)
        .curveTo(-13.2, -51.5, -14, -51.5)
        .closePath()
      this.shape_11.setTransform(-21.4, -6, 0.53, 0.53)

      this.shape_12 = new cjs.Shape()
      this.shape_12.graphics
        .beginFill('#455D5C')
        .beginStroke()
        .moveTo(-46.3, 109.7)
        .curveTo(-67.7, 100.6, -84.2, 84.2)
        .curveTo(-100.6, 67.7, -109.7, 46.3)
        .curveTo(-119, 24.2, -119, -0)
        .curveTo(-119, -24.2, -109.7, -46.3)
        .curveTo(-100.6, -67.7, -84.2, -84.2)
        .curveTo(-67.7, -100.6, -46.3, -109.7)
        .curveTo(-24.2, -119, 0, -119)
        .curveTo(24.2, -119, 46.3, -109.7)
        .curveTo(67.7, -100.6, 84.2, -84.2)
        .curveTo(100.6, -67.7, 109.7, -46.3)
        .curveTo(119, -24.2, 119, -0)
        .curveTo(119, 24.2, 109.7, 46.3)
        .curveTo(100.6, 67.7, 84.2, 84.2)
        .curveTo(67.7, 100.6, 46.3, 109.7)
        .curveTo(24.2, 119, 0, 119)
        .curveTo(-24.2, 119, -46.3, 109.7)
        .closePath()
      this.shape_12.setTransform(-1.5, -0.5, 0.53, 0.53)

      this.timeline.addTween(
        cjs.Tween.get({})
          .to({ state: [] })
          .to(
            {
              state: [
                { t: this.shape_12 },
                { t: this.shape_11 },
                { t: this.shape_10 },
                { t: this.shape_9 },
                { t: this.shape_8 },
                { t: this.shape_7 },
                { t: this.shape_6 },
                { t: this.shape_5 },
                { t: this.shape_4 },
                { t: this.shape_3 },
                { t: this.shape_2 },
                { t: this.shape_1 },
                { t: this.shape },
              ],
            },
            5
          )
          .wait(59)
      )
    }).prototype = p = new cjs.MovieClip()
  p.nominalBounds = new cjs.Rectangle(-65.6, -64.6, 128.3, 128.3)

    // stage content:

    ; (lib.logo_animation = function (mode, startPosition, loop) {
      this.initialize(mode, startPosition, loop, {})

      // timeline functions:
      this.frame_55 = function () {
        /* Stop at This Frame
The  timeline will stop/pause at the frame where you insert this code.
Can also be used to stop/pause the timeline of movieclips.
*/

        this.stop()
      }

      // actions tween:
      this.timeline.addTween(
        cjs.Tween.get(this)
          .wait(55)
          .call(this.frame_55)
          .wait(1)
      )

      // Animation
      this.instance = new lib.Animation()
      this.instance.setTransform(164.2, 164.3, 1.772, 1.772)

      this.timeline.addTween(cjs.Tween.get(this.instance).wait(56))

      // Layer 1
      this.instance_1 = new lib.WOM0130_TABVBG()
      this.instance_1.setTransform(0, 0.8)

      this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(56))
    }).prototype = p = new cjs.MovieClip()
  p.nominalBounds = new cjs.Rectangle(160.5, 156.3, 321, 311)
})(
  (window.lib = window.lib || {}),
  (window.images = window.images || {}),
  createjs
)
